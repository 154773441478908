<template>
  <div class="about-us-item">
    <h1>医赋知识（北京）科技有限公司简介</h1>
    <p>
      医赋知识（北京）科技有限公司是一家有着医学数据库基因的互联网科技公司，凭借资深的医疗科技团队，专业细致的内容团队，领衔的医学团队、医疗翻译团队、专业服务团队形成闭环为客户提供一站式、全方位的医学服务。医赋科技的业务范围覆盖广泛，APP产品丰富多样，致力于客户提供理想中的极致服务。
    </p>
    <p>
      其强大的海外医疗资源整合能力源于其母公司麻省医疗国际—一家由哈佛医学教授团队创建的国际专业化的医疗服务机构。
    </p>
    <h3 class="title">公司愿景</h3>
    <p>
      成为世界一流的专注于医学知识的科技公司。
    </p>
    <h3 class="title">公司使命</h3>
    <p>
      传递医学前沿知识，助力医生职业成长和国际医学交流。
    </p>
    <h3 class="title">文化价值观</h3>
    <p>
      真诚、共享、创新、进取
    </p>
    <h3 class="title">业务范围</h3>
    <p>
      Info X Med 是一款涵盖国内外全新及权威医学知识，基于数据挖掘及AI技术，为全国大三甲医院临床科研主任、副主任、主治医生、住院医师等医护人员个性化推送医学知识和医学学习、交流机会的APP。
    </p>
    <h3 class="title">产品综述</h3>
    <p>
      INFO X MED
      是一款深耕医学领域的知识付费产品，秉持着知识赋能医生的产品理念，汇集了国际权威、专业医学内容，依托超千万量级权威数据库，旨在为广大医师提供一键精准搜索的医学服务，传递国内外全新的医学知识，全方位助力医生成长。
    </p>
    <h3 class="title">产品优势</h3>
    <p>
      本着专业、优质的服务精神，依托哈佛医学院丰富的医疗资源和优势，INFO X MED
      首创研发的大咖学院聚合了国际学科前沿课程拥，有全球顶尖的师资资源，便利共享海外研修线上课程，与千万医护人员一起分享世界的医学研究成果，足不出户即可享受到国外优质医学教育资源。
    </p>
    <p>
      INFO X MED 始终坚信只有知识才能赋能医生，随时随地让所有医疗行业从业者平等的提升自我是INFO X MED 不变的使命。
    </p>
  </div>
</template>
<script>
export default {
  data () {
    return {
      imgSrc: []
    }
  }
}
</script>
<style lang="less" scoped>
.about-us-item {
  h1 {
    padding: 0 0 30px;
    letter-spacing: 1px;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
  }

  p {
    font-size: 15px;
    line-height: 1.5em;
    margin-bottom: 10px;
    // text-indent: 36px;
  }

  .title {
    font-size: 16px;
    color: #2b72e0;
    margin-bottom: 10px;
  }
}
</style>
